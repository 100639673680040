import React, { useEffect, useState ,useContext} from 'react';
import {
  Box, Grid, Card, CardContent, CardMedia, Typography, Button,InputLabel,MenuItem,FormControl,Select,
  LinearProgress, CardActions, IconButton,Tabs, Tab
} from '@mui/material';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import Divider, { dividerClasses } from '@mui/material/Divider';
import Layout from '../Layout/Layout';
import { styled } from '@mui/material/styles';

const BodyContent = styled(Box)(({ theme }) => ({
  marginTop: "120px",
  overflowY: "auto",
  height: "calc(100vh - 120px)", // Remaining height for content
}));

const ContentBox = styled("div")(({ theme }) => ({
  margin: "85px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));

const SchoolDistrict = () => {
    const [selectLevel, setSelectLevel] = React.useState('');
    const [value, setValue] = useState(0);

    const handleChange = (event) => {
        setSelectLevel(event.target.value);
    };

    const handleChangeTab = (event, newValue) => {
      setValue(newValue);
    };
  

  return (
    <Box sx={{ overflow: "hidden", height: "100vh" }}>
      <Layout />
      <BodyContent>
        <ContentBox>
          <Typography variant="h5">Course Catalog 2024</Typography>
          <Typography variant="h6" style={{color:'grey'}}>Technology Education & Opportunity</Typography>
          <Divider sx={{ marginY: 2 }} />
       
         
      <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderColor: 'divider',
        [`& .${dividerClasses.root}`]: {
          mx: 0.5,
        },
      }}
    >
      <FormControl sx={{ m: 1, minWidth: 120,mr: 5 }}>
        <Select
          value={selectLevel}
          onChange={handleChange}
          //displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            borderRadius: '16px', // Adjust the value for more or less rounding
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: '25px', // Ensures the outline also has rounded corners
            },
          }}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="adults">Adults</MenuItem>
          <MenuItem value="middleSchool">Middle School</MenuItem>
        </Select>
       
      </FormControl>
      <Divider orientation="vertical" flexItem />
      
      <Tabs
        value={value}
        onChange={handleChangeTab}
        centered
        variant="scrollable"
        scrollButtons
        TabIndicatorProps={{
          style: {
            display: "none", // Hide the indicator
          },
        }}
        sx={{
          "& .MuiTab-root": {
            borderRadius: "25px", // Rounded corners
            textTransform: "none", // Prevent uppercase text
            border: "1px solid #ccc", // Border for button appearance
            padding: "8px 16px",
            margin: "0 8px", // Add spacing between buttons
            backgroundColor: "#f5f5f5", // Background color
            color: "#000", // Text color
            "&.Mui-selected": {
              backgroundColor: "#e0e0e0", // Active button color
              color: "#2e7d32", // Active button text color
            },
            "&:hover": {
              backgroundColor: "#e0e0e0", // Hover color
            },
            border:'1px solid rgba(46, 125, 50, 0.5)'
          },
        }}
      >
        <Tab label="Robotics" />
        <Tab label="Invesment" />
        <Tab label="Entrepreneuship" />
        <Tab label="Artificial Intelligence and Machine Learning" />
        <Tab label="Robotics" />
        <Tab label="Invesment" />
        <Tab label="Entrepreneuship" />
        <Tab label="Artificial Intelligence and Machine Learning" />
        
      </Tabs>
    </Box>
 {/* <Box sx={{ minWidth: 120 }}>
      <FormControl sx={{ m: 1, minWidth: 100 }}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectLevel}
          label="All"
          onChange={handleChange}
          sx={{
            borderRadius: '16px', // Adjust the value for more or less rounding
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: '25px', // Ensures the outline also has rounded corners
            },
          }}
        >
          <MenuItem value={10}>All</MenuItem>
          <MenuItem value={20}>Adults</MenuItem>
          <MenuItem value={30}>Middle School</MenuItem>
        </Select>
      </FormControl>
    </Box> */}
        </ContentBox>
      </BodyContent>
    </Box>
  );
};

export default SchoolDistrict;
