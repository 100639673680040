import { useState,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, Checkbox, Grid, TextField, Box, styled, useTheme ,Snackbar,Typography} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import * as Yup from "yup";
import { SignInFunction, SignInGoogleFunction } from '../../Redux/slices/UserSlice/UserAPI';
import { Paragraph } from "../../Components/Typography";
import { useSelector, useDispatch } from 'react-redux'
import { resetSuccess } from '../../Redux/slices/UserSlice/UserSlice';
import Layout from '../Layout/Layout';
import Footer from "../Layout/Footer";
import { GoogleLogin } from '@react-oauth/google';

// STYLED COMPONENTS
const BodyContent = styled(Box)(({ theme }) => ({
   
  marginTop: "30px", 
  overflowY: "auto",
 // height: "calc(100vh - 120px)", 
 
}));
// const ContentBox = styled("div")(({ theme }) => ({
//   marginTop:'20px'
// }));
const FlexBox = styled(Box)(() => ({
  display: "flex"
}));
const HeaderTextStyle = {
  textAlign: 'center',
  marginBottom: '3rem',
  fontSize: '1.3rem',
  marginTop:'17px',
  color:'black'
 }
const ContentBox = styled("div")(() => ({
  height: "100%",
  padding: "32px",
  position: "relative",
 // background: "rgba(0, 0, 0, 0.01)"
}));

const StyledRoot = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  //backgroundColor: "#275317",
  minHeight: 812,
  //minHeight: "100% !important",
  "& .card": {
    maxWidth: 800,
    //minHeight: 400,
    margin: "1rem",
    //display: "flex",
    // borderRadius: 12,
    // alignItems: "center"
  },

  ".img-wrapper": {
    height: "100%",
    minWidth: 320,
    display: "flex",
    padding: "1rem",
    alignItems: "center",
    justifyContent: "center"
  }
}));

// initial login credentials
const initialValues = {
  email: "",
  password: "",
  
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be 6 character length")
    .required("Password is required!"),
  email: Yup.string().email("Invalid Email address").required("Email is required!")
});

export default function JwtLogin() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let message = useSelector(state => state.User?.message);
  let success = useSelector(state => state.User?.success);

  useEffect(() => {
    console.log('in use effect.......................')
    if(success) {
        // Redirect to a different page upon successful sign-up
        navigate('/');
    }
  }, [success]);

  useEffect(() => {
    // Cleanup function to reset success when component unmounts
    return () => {
      dispatch(resetSuccess());
    };
  },[dispatch]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      let email = values.email
      let password = values.password
      setOpen(true);
     await dispatch(
        SignInFunction({
          email, 
          password
        })
      )
      // await login(values.email, values.password);
    
      //navigate("/");
    } catch (e) {
      setLoading(false);
    }
  };
  const responseMessage = async (response) => {
    console.log(response);
    let client_id = response.clientId
    let credential = response.credential
    await dispatch(
      SignInGoogleFunction({
        credential, client_id
      })
    )
   
};
const errorMessage = (error) => {
    console.log(error);
};
  return (
    <Box  sx={{
      overflow: "hidden", 
      height: "100vh", 
    }}>
<Layout/>

<BodyContent>
<ContentBox>
    <StyledRoot>
      
      <Card sx={{
       boxShadow: "5px 10px 18px #888888", 
       borderRadius: 3, 
       
      }} className="card">
        <Grid container spacing={0}>
        <Grid style={{backgroundColor:'#275317'}}item sm={6} xs={12}>
          
            <div className="img-wrapper">
              <img src="WhiteSpringBoardLogo.png" width="100%" alt="" />
            </div>
           
          </Grid>
          <Grid item sm={6} xs={12}>
            <ContentBox>
            <Typography sx={HeaderTextStyle}><b>LOG IN TO CONTINUE YOUR LEARNING JOURNEY</b></Typography>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.email}
                      onChange={handleChange}
                      helperText={touched.email && errors.email}
                      error={Boolean(errors.email && touched.email)}
                      sx={{ mb: 3 }}
                    />
                    

                    <TextField
                      fullWidth
                      size="small"
                      name="password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.password}
                      onChange={handleChange}
                      helperText={touched.password && errors.password}
                      error={Boolean(errors.password && touched.password)}
                      sx={{ mb: 1.5 }}
                    />

                    <FlexBox justifyContent="space-between">
                      <FlexBox gap={1}>
                      </FlexBox>

                      <NavLink
                        to="/forgetpassword"
                        style={{ color: theme.palette.primary.main }}
                      >
                        Forgot password?
                      </NavLink>
                    </FlexBox>

                    <LoadingButton
                      type="submit"
                      //color="primary"
                      //loading={loading}
                      variant="contained"
                      style={{backgroundColor: "#275317"}}
                      sx={{ my: 2 }}
                    >
                      Login
                    </LoadingButton>
                    
                    <Paragraph>
                      Don't have an account?
                      <NavLink
                        to="/signup"
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}
                      >
                        Register
                      </NavLink>
                    </Paragraph>
                    <br></br>
                    <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                  </form>
                )}
              </Formik>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                severity="warning"
                autoHideDuration={2000}
                message={message}        
         //  action={action}
       />
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
      
    </StyledRoot>
    {/* <Footer/> */}
    </ContentBox>
    
</BodyContent>


</Box>
  );
}

// import { useState, useEffect } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import { Card, Grid, TextField, Box, styled, useTheme, Snackbar } from "@mui/material";
// import { LoadingButton } from "@mui/lab";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import { SignInFunction } from '../../Redux/slices/UserSlice/UserAPI';
// import { Paragraph } from "../../Components/Typography";
// import { useSelector, useDispatch } from 'react-redux';
// import { resetSuccess } from '../../Redux/slices/UserSlice/UserSlice';

// // STYLED COMPONENTS
// const FlexBox = styled(Box)(() => ({
//   display: "flex"
// }));

// const ContentBox = styled("div")(() => ({
//   height: "100%",
//   padding: "32px",
//   position: "relative",
//  // background: "rgba(0, 0, 0, 0.01)"
// }));

// const StyledRoot = styled("div")(() => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   //backgroundColor: "#275317",
//   minHeight: 812,
//   //minHeight: "100% !important",
//   "& .card": {
//     maxWidth: 800,
//     minHeight: 400,
//     margin: "1rem",
//     //display: "flex",
//     // borderRadius: 12,
//     // alignItems: "center"
//   },

//   ".img-wrapper": {
//    // height: "100%",
//     minWidth: 320,
//     display: "flex",
//     padding: "2rem",
//     alignItems: "center",
//     justifyContent: "center"
//   }
// }));

// // Initial login credentials
// const initialValues = {
//   email: "",
//   password: "",
// };

// // Form field validation schema
// const validationSchema = Yup.object().shape({
//   password: Yup.string()
//     .min(6, "Password must be 6 character length")
//     .required("Password is required!"),
//   email: Yup.string().email("Invalid Email address").required("Email is required!")
// });

// export default function JwtLogin() {
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(false);
//   const [open, setOpen] = useState(false);
//   let message = useSelector(state => state.User?.message);
//   let success = useSelector(state => state.User?.success);

//   useEffect(() => {
//     if (success) {
//       // Redirect to a different page upon successful sign-up
//       navigate('/');
//     }
//   }, [success]);

//   useEffect(() => {
//     // Cleanup function to reset success when component unmounts
//     return () => {
//       dispatch(resetSuccess());
//     };
//   }, [dispatch]);

//   const handleFormSubmit = async (values) => {
//     setLoading(true);
//     try {
//       let email = values.email;
//       let password = values.password;
//       setOpen(true);
//       await dispatch(
//         SignInFunction({
//           email,
//           password
//         })
//       );
//     } catch (e) {
//       setLoading(false);
//     }
//   };

//   return (
//     <StyledRoot>
//       <Grid container spacing={0}>
//         <Grid item sm={6} xs={12} sx={{
//           backgroundColor: '#275317',
//           color: 'white', // Ensure text contrast
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//           height: "100vh", // Ensure it takes full viewport height
//         }}>
//           <div className="img-wrapper">
//             <img src="WhiteSpringBoardLogo.png" width="100%" alt="" />
//           </div>
//         </Grid>
//         <Grid item sm={6} xs={12}>
          
//             <ContentBox>
//               <Formik
//                 onSubmit={handleFormSubmit}
//                 initialValues={initialValues}
//                 validationSchema={validationSchema}
//               >
//                 {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
//                   <form onSubmit={handleSubmit}>
//                     <TextField
//                       fullWidth
//                       size="small"
//                       type="email"
//                       name="email"
//                       label="Email"
//                       variant="outlined"
//                       onBlur={handleBlur}
//                       value={values.email}
//                       onChange={handleChange}
//                       helperText={touched.email && errors.email}
//                       error={Boolean(errors.email && touched.email)}
//                       sx={{ mb: 3 }}
//                     />

//                     <TextField
//                       fullWidth
//                       size="small"
//                       name="password"
//                       type="password"
//                       label="Password"
//                       variant="outlined"
//                       onBlur={handleBlur}
//                       value={values.password}
//                       onChange={handleChange}
//                       helperText={touched.password && errors.password}
//                       error={Boolean(errors.password && touched.password)}
//                       sx={{ mb: 1.5 }}
//                     />

//                     <FlexBox justifyContent="space-between">
//                       <FlexBox gap={1}></FlexBox>
//                       <NavLink
//                         to="/forgetpassword"
//                         style={{ color: theme.palette.primary.main }}
//                       >
//                         Forgot password?
//                       </NavLink>
//                     </FlexBox>

//                     <LoadingButton
//                       type="submit"
//                       variant="contained"
//                       style={{ backgroundColor: "#275317" }}
//                       sx={{ my: 2 }}
//                     >
//                       Login
//                     </LoadingButton>

//                     <Paragraph>
//                       Don't have an account?
//                       <NavLink
//                         to="/signup"
//                         style={{ color: theme.palette.primary.main, marginLeft: 5 }}
//                       >
//                         Register
//                       </NavLink>
//                     </Paragraph>
//                   </form>
//                 )}
//               </Formik>
//               <Snackbar
//                 anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
//                 open={open}
//                 severity="warning"
//                 autoHideDuration={2000}
//                 message={message}
//               />
//             </ContentBox>
         
//         </Grid>
//       </Grid>
//     </StyledRoot>
//   );
// }


