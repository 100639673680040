import { useEffect,useState ,useContext} from 'react';
import { Typography, Box, Button, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Grid, Container,CardMedia, Divider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import BackgroundImage from '../../../src/Course_background.jpg'
import Layout from '../Layout/Layout';
import { CoursePaymentFunction,CourseDetailFunction } from '../../Redux/slices/CourseSlice/CourseAPI';
import { CartContext } from '../ShoppingCart/CartContext';
import courseContent from './CourseContent.json'
const BodyContent = styled(Box)(({ theme }) => ({
   
    marginTop: "120px",      
    overflowY: "auto",
    height: "calc(100vh - 120px)", 
   
  }));
  const ContentBox = styled("div")(({ theme }) => ({
    marginTop:'20px'
  }));
const CourseDetail = () => {
  const location = useLocation();
  const [userPresent,setUserPresent] =useState(false)


  const navigate = useNavigate();
  const dispatch = useDispatch();

  let courseDetails = location.state == null ?'' :location.state.courseDetails;  
  let token = useSelector(state => state.User?.jwt_token);
console.log('courseDetails',courseDetails)
 
  useEffect(() => {
      
    //setCartData(data)
    let jwt_token = localStorage.getItem('jwt_token_springboard')

    if(jwt_token == null){
        setUserPresent(false)
    }else if(jwt_token){
        setUserPresent(true)
    }
    
    

  },[]) 
  const handleClickOfCheckout  = async () => {
    console.log('handleclick of checkout',userPresent)
    if(userPresent === true){
      const courseIdArray = [  courseDetails.course_id ];
        console.log('courseIdArray',courseIdArray)
     
      const response = await dispatch(CoursePaymentFunction({
        token,
        course_ids: courseIdArray
       }))
  
       
       if(response.payload.data.approvalUrl){
        window.location.href = response.payload.data.approvalUrl;
   
       }
    }else if(userPresent===false){
     navigate("/signin"); 
    }
    

  }

  const handleClickOfStartButton = ()=>{
    navigate('/myCourse')
    }
let image =  courseDetails.imageName 
 return (
  <>
  
  <Box  sx={{
            overflow: "hidden", 
            height: "100vh", 
          }}>
  <Layout/>

  <BodyContent>
  <ContentBox>
  <Box sx={{ color: "black", py: 5, position: "relative", minHeight: "300px",
    
      backgroundColor:'ivory' 
    
}}>
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                <Typography variant="h3" gutterBottom>
                  {courseDetails.course_name}
                </Typography>
                <Typography  variant="h6" gutterBottom>
                {courseDetails.course_description}
                
                </Typography>
              </Box>
            </Grid>

         
            <Grid item xs={12} md={4} sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  right: '-50px', 
                  top: '-50%',     
                  transform: 'translateY(-10%)',
                  zIndex: 1,
                  
                }}
              >
                <Card
                  sx={{
                    width: "300px",
                    maxWidth: "300px",
                    boxShadow: 3,
                    height:"410px"
                  }}
                >
                      <CardMedia
              sx={{ height: 200 }}
              image={`/images/${courseDetails.course_picture}`}
              //image={courseDetails.course_picture}
              title="green iguana"
            />
                  <CardContent>
                    <Typography variant="h6" component="div" sx={{ mb: 1 }}>
                      ${courseDetails.price} <span style={{ textDecoration: 'line-through', color: '#9e9e9e' }}>$240</span>
                    </Typography>
                    {courseDetails && courseDetails.purchasedCourseDetails
                      &&
                      <Button 
                      color="success" 
                      variant="outlined" 
                      size="small" 
                      fullWidth
                      onClick ={handleClickOfStartButton}
                    >
                    
                       <span>Continue</span>
                    </Button>
                    }
                    {courseDetails && !courseDetails.purchasedCourseDetails
                    &&
                    <Button 
                    color="success" 
                    variant="outlined" 
                    size="small" 
                    fullWidth
                    onClick ={handleClickOfCheckout}
                  >
                  
                   <span>Buy Now</span>
                  </Button>
                    }
                   
                  
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="h6" gutterBottom>What's included</Typography>
                      <Typography variant="body2">20.2 hours on-demand video</Typography>
                      <Typography variant="body2">80 Lessons</Typography>
                      <Typography variant="body2">Certificate of completion</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container sx={{ mt: 6 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" gutterBottom>
             <b> <u>Course Content</u></b>
            </Typography>
            <div>
             <br></br>
      {courseContent.map((course, courseIndex) => (
        
          image == course.imageName &&
        
       
        <div key={courseIndex}>
         
          {Object.keys(course)
            .filter((key) => key.startsWith("section"))
            .map((sectionKey, sectionIndex) => {
              const section = course[sectionKey];
              return (
                <Accordion key={sectionIndex}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                     <b> {section.sectionId}</b>: <b>{section.sectionTitle}</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {section.whatsIcluded.map((item, index) => (
                        <li key={index}>
                          <Typography>{item}</Typography>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
      
      ))}
    </div>
          </Grid>
        </Grid>
      </Container>
  </ContentBox>

   </BodyContent>
   </Box>
  </>  
 )   
}

export default CourseDetail;