import { useEffect,useState ,useContext} from 'react';
import { 
    Grid,
    AppBar,
    Toolbar,
    Typography,
    Box,
    IconButton ,
    Badge,
    Card,
    CardContent,
    CardMedia,
    Paper,
    Divider,
    Snackbar,
   

} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from 'semantic-ui-react'
import { useSelector,useDispatch } from 'react-redux';
import { CoursePaymentFunction,CourseDetailFunction } from '../../Redux/slices/CourseSlice/CourseAPI';
import Layout from '../Layout/Layout';
import { useNavigate, useLocation } from 'react-router-dom';
import { CartContext } from './CartContext';

const BodyContent = styled(Box)(({ theme }) => ({
  marginTop: "120px", 
  overflowY: "auto",
  height: "calc(100vh - 120px)", // Remaining height for content (adjust as needed)
 
}));
const ContentBox = styled("div")(({ theme }) => ({
  margin: "85px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));
  const ShoppingCart = ({}) => {
   
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { cartItems, removeFromCart } = useContext(CartContext);
    let purchased_course_data = useSelector(state => state.Course?.purchased_course_data);
    const [userPresent,setUserPresent] =useState(false)
    let token = useSelector(state => state.User?.jwt_token);
   let data = location.state == null ?[] :location.state.cartData;  

    const [totalAmount, setTotalAmount] = useState([]);
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [cartData, setCartDataInShoppingCart] = useState(data);
    let amount =0;
    let jwt_token = localStorage.getItem('jwt_token_springboard')
    console.log('jwt token',jwt_token)
    console.log('cartData',cartData)
    
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const msg = searchParams.get('message');
      if (msg) {
        setOpen(true)
        setMessage(msg);
      }
    }, [location.search]);

    useEffect(() => {
      
        //setCartData(data)
        let jwt_token = localStorage.getItem('jwt_token_springboard')
  
        if(jwt_token == null){
            setUserPresent(false)
        }else if(jwt_token){
            setUserPresent(true)
        }
        //removeFromCart(course)
        //purchased_course_data
        //const updatedCart = cart.filter(item => item.course_id !== course.course_id);
        console.log('cartitems',cartItems);
        console.log('purchased course',purchased_course_data)
        // const filteredCartItems = cartItems.filter(cartItem =>
        //   !purchased_course_data.some(purchased_course_data => purchased_course_data.course_id === cartItem.course_id)
        // );
        const filteredCartItems = cartItems.filter(cartItem =>
          purchased_course_data.some(purchasedItem => purchasedItem.course_id === cartItem.course_id)
        );
        console.log('filteredCartItems..............',filteredCartItems)
         removeFromCart(filteredCartItems)
        cartItems && cartItems.map(course =>{
                amount= amount +  parseInt(course.price, 10)
            })
        setTotalAmount(amount)
        
    
      },[]) 


      const handleClickOfCheckout  = async () => {
        if(userPresent === true){
          const courseIdsArray = cartItems.map(course => course.course_id);
          console.log('courseIdsArray',courseIdsArray)
          const response = await dispatch(CoursePaymentFunction({
            token,
            course_ids: courseIdsArray
           }))
      
           
           if(response.payload.data.approvalUrl){
            window.location.href = response.payload.data.approvalUrl;
          //  window.open(response.payload.data.approvalUrl, '');
           }
        }else if(userPresent===false){
          navigate("/signin"); 
        }
        

      }
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

    const handleButtonOfRemove = (course)=>{
      removeFromCart(course)
      
        
      const updatedCartData = cartItems.filter(item => item.course_id !== course.course_id);
     
      updatedCartData && updatedCartData.map(course =>{
        amount= amount +  parseInt(course.price, 10)
    })
      setTotalAmount(amount)
     
      setCartDataInShoppingCart(updatedCartData); 
     
      }

 return (
  <>
  {/* <NavigationBarPage /> */}
  <Box  sx={{
            overflow: "hidden", // Prevent outer scroll
            height: "100vh", // Ensure the height covers the viewport
          }}>
  <Layout />
  <BodyContent>
  <ContentBox>
  <div style={{padding:'3%'}}>
  <h2>Shopping cart</h2>
  {cartItems && cartItems.length>0 && <h4> { cartItems &&cartItems.length} Courses in cart</h4>}
  
  <Divider/>
  <br></br>
  <br></br>
    

  <Grid container spacing={3} >
      {/* Left Half */}
      {cartItems && cartItems.length>0 ? 
    cartItems && cartItems.map(course =>(
      <Grid container item xs={12} md={8}>
      <Card sx={{ display: 'flex' }}>
       
          {/* Top Left Quarter */}

          <Grid item xs={12} sm={3}>
          
                    <CardMedia
            component="img"
                height="100%"
                image={`/images/${course.course_picture}`}
           // image={course.course_picture}
            alt="Live from space album cover"
            />
            
          </Grid>
          <Grid item xs={12} sm={6}>
           
                    <CardContent>
                <Typography component="div" >
                    
                <b>{course.course_name}</b> : {course.course_description}
            
                </Typography>
                <Typography
                variant="subtitle1"
                component="div"
                sx={{ color: 'text.secondary' }}
                >
                By Dr. Steven Lindo
                </Typography>
            </CardContent>
           
          </Grid>

        
          <Grid item xs={12} sm={3}>
           
                    <CardContent>
            <Typography  style ={{textAlign:'end'}}component="div"
                sx={{ color: 'text.secondary' }}>
            $ {course.price} 
           
            </Typography>
            
            <Button  onClick={() => handleButtonOfRemove(course)} style={{marginLeft:'52%',marginTop:'10%'}}>Remove</Button>
            </CardContent>
           
          </Grid>

       </Card>
        
      </Grid>
     
     ))
  
  
     : <h2 style={{margin:'2%',color:'darkgrey'}}>There are no courses in the cart</h2>}
      {/* Right Half */}
      
      <Grid container item xs={12} md={4}>
      <Grid item xs={12} sm={6}>
        </Grid>
        {cartItems && cartItems.length>0 &&  <Grid item xs={12} sm={6}>
        <Typography style={{marginLeft:'50%',marginTop:'10%'}} ><h3>Sub Total :</h3></Typography>
       <Typography style={{marginLeft:'52%',marginTop:'10%'}} > <h3>$ {totalAmount}</h3></Typography>
        <Button  onClick ={handleClickOfCheckout}style={{marginLeft:'52%',marginTop:'10%'}} >Checkout</Button>
        </Grid>
  }
      </Grid>
     
      <Snackbar
         anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         open={open}
         severity="success"
         autoHideDuration={3000}
         message={message}
        onClose={handleClose}
        //  action={action}
       />
    </Grid>
    
   
  </div>
  </ContentBox>
  </BodyContent>
  
   </Box>
  </>  
 )   
}

 export default ShoppingCart;