import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import environment from '../../../environment'
import { Token } from '@mui/icons-material';
 axios.defaults.withCredentials = true;


 export const isAuthenticated =  createAsyncThunk(
  'api/isAuthenticated',
  async ({user_data}) => {

    

    const res = await axios.post(
      `${environment.ServerURL}/isAuthenticated`,
      {
        user_data
      }
     
    )

    return res  
  }
);

export const SignUpFunction = createAsyncThunk(
    'api/signup',
    async ({ firstName, lastName, email, password, confirmPassword, profession,isVerified }, { rejectWithValue }) => {

        

      try {
        const res = await axios.post(
          `${environment.ServerURL}/signup`,
          { 
            firstName,
            lastName,
            email, 
            password,
            confirmPassword,
            profession,
            isVerified
          }
        );

        return res.data; 
      
      } 
      catch (error) {
      
         return rejectWithValue(error?.response?.data); 
      }
    }   
  )

export const SignInFunction = createAsyncThunk(
    'api/signin',
   async ({ email, password }, { rejectWithValue }) => {

    try {
      const res = await axios.post(
        `${environment.ServerURL}/signin`,
        { email, password }
      );
      return res.data; 
    } catch (error) {
   
       return rejectWithValue(error?.response?.data); 
     }
    }       

  
  
  )

export const SignInGoogleFunction = createAsyncThunk(
  'api/google',
 async ({ credential, client_id }, { rejectWithValue }) => {

  console.log('credential',credential)
  console.log('client_id',client_id)
  try {
    const res = await axios.post(
      `${environment.ServerURL}/google`,
      { 
        credential, client_id
      }
    );

      console.log('RES.................',res)
    return res.data; 
  } catch (error) {
 
     return rejectWithValue(error?.response?.data); 
   }
  }       



)

  export const ConfirmAccountFunction =  createAsyncThunk(
    'api/confirmAccount',
    async ({ id }) => {

    

      const res = await axios.post(
        `${environment.ServerURL}/confirmAccount`,
        { id }
      )
  
   
        
      return res  
    }
  );

  export const ForgotPasswordOtpFunction =  createAsyncThunk(
    'api/forgotPassword/sendOTP',
    async ({ email }) => {

   
       
      // const res = await axios.get(`${environment.ServerURL}/forgotPassword/sendOTP?email=${email}`, {
      //   withCredentials: true 
      // })
    
      const res = await axios.post(`${environment.ServerURL}/forgotPassword/sendOTP?`, {
        email,withCredentials: true 
      })
    
        
      return res  
    }
  )
  
  export const ForgotPasswordOtpConfirmFunction =  createAsyncThunk(
    'api/confirmOtp',
    async ({ email,otp,tempToken}) => {

     
      
      
      const res = await axios.post(
        `${environment.ServerURL}/confirmOtp`, 
          { email,otp ,tempToken,withCredentials:true},

      )
    
      return res  
    }
  )
  export const ForgetPasswordChangePasswordFunction =  createAsyncThunk(
    'api/changePasswordAfterOtpVerification',
    async ({ email,password,confirmPassword }) => {

      const res = await axios.post(
        `${environment.ServerURL}/changePasswordAfterOtpVerification`, 
          { email,password ,confirmPassword, withCredentials:true}

      )
    
        
      return res  
    }
  )


  export const logoutFunction =  createAsyncThunk(
    'api/logout',
    async () => {
      const token = localStorage.getItem('jwt_token_springboard');
    
      const config = {
        headers: {
          
          'Authorization':`Bearer ${token}`, // Replace with actual token
          'Content-Type': 'application/json',
          // Add more headers as needed
        }
      }
      const res = await axios.post(
        `${environment.ServerURL}/logout`, 
        {token},
        config
       
      )
  
      return res  
    }
  );
